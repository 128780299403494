import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { withRouter } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import { products } from '../../data/product';

import '../pages/styles/details.css'

const aboutbg = require('./../../assets/images/background/image-11.jpg');
const helpbg = require('./../../assets/images/background/image-18.jpg');

const percentage1 = 80;

const ServicesDetails = (props) => {
    const location = useLocation();
    const { name } = props?.match?.params
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        phone: '',
        address: '',
        product: selectedProduct?.name
    })

    const sendEmail = async (e) => {
        e.preventDefault();
        console.log("workssss", e);

        window.Email.send({
            Host: "smtp.elasticemail.com",
            Port: 2525,
            Username: "marketing@prolabsteroids.com",
            Password: "4473F16DE8792142479285FDAE056D7D880A",
            To: 'sales@prolabsteroids.com',
            From: "marketing@prolabsteroids.com",
            Subject: "Order From Pro Lab Website",
            Body: `<p><p><br><p>Product: ${formData.product || selectedProduct?.name}</p><br><p>Name: ${formData.username}</p><br><p>Email: ${formData.email}</p><br>
            <p>Phone: ${formData.phone}</p><br><p>Address: ${formData.address}</p><br><p><p>`
        }).then(
            message => alert("Your order submitted successfully")
        );
    };

    useEffect(() => {
        // history.push('/');
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        let index = products.vialProducts?.products.findIndex(d => d.slug === name);
        let i = products.tabletProducts?.products.findIndex(d => d.slug === name)
        if (index < 0 && i >= 0) {
            setSelectedProduct(products.tabletProducts?.products[i]);
        } else {
            setSelectedProduct(products.vialProducts?.products[index]);
        }
    }, [])

    useEffect(() => {
        console.log("Location state updated:", name);
    }, [location.state]);

    const handleEnquiryFormSubmit = (e) => {
        e.preventDefault();
    };

    const handleButtonClick = (item) => {
        props.history.push(`/product/${item.slug}`, { item: item });
    };


    return (
        <>
            <Header />

            {/* <!--Search Popup--> */}
            <div id="search-popup" className="search-popup">
                <div className="close-search theme-btn"><span className="flaticon-cancel"></span></div>
                <div className="popup-inner">
                    <div className="overlay-layer"></div>
                    <div className="search-form">
                        <form>
                            <div className="form-group">
                                <fieldset>
                                    <input type="search" className="form-control" name="search-input" value="" placeholder="Search Here" required />
                                    <input type="submit" value="Search Now!" className="theme-btn" />
                                </fieldset>
                            </div>
                        </form>
                        <br />
                        <h3>Recent Search Keywords</h3>
                        {/* <ul className="recent-searches">
                            <li><Link to={'/#'}>Finance</Link></li>
                            <li><Link to={'/#'}>Idea</Link></li>
                            <li><Link to={'/#'}>Service</Link></li>
                            <li><Link to={'/#'}>Growth</Link></li>
                            <li><Link to={'/#'}>Plan</Link></li>
                        </ul> */}
                    </div>

                </div>
            </div>

            {/* <!-- Page Banner Section --> */}
            <section className="page-banner">
                <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                <div className="bottom-rotten-curve alternate"></div>

                <div className="auto-container">
                    <h1>Product Details</h1>
                    <ul className="bread-crumb clearfix">
                        <li><Link to={'/#'}>Home</Link></li>
                        <li className="active">Product</li>
                    </ul>
                </div>

            </section>
            {/* <!--End Banner Section --> */}


            {/* <!-- Sidebar Page Container --> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-4">
                            <aside className="sidebar">
                                {/* <!-- Category Widget --> */}

                                {/* <div className="sidebar-widget-two categories-widget-two">
                                        <div className="widget-content">
                                            <ul>
                                                <li><Link to={'/services-details'}>Financial Planning <i className="fa fa-angle-right"></i></Link></li>
                                                <li className="current"><Link to={'/services-details'}>Software & Research <i className="fa fa-angle-right"></i></Link></li>
                                                <li><Link to={'/services-details'}>Business Services <i className="fa fa-angle-right"></i></Link></li>
                                                <li><Link to={'/services-details'}>Quality Resourcing <i className="fa fa-angle-right"></i></Link></li>
                                                <li><Link to={'/services-details'}>Travel and Aviation <i className="fa fa-angle-right"></i></Link></li>
                                                <li><Link to={'/services-details'}>Healthcare Services <i className="fa fa-angle-right"></i></Link></li>
                                            </ul>
                                        </div>
                                    </div> */}

                                {/* <!-- Contact Form Widget --> */}
                                <div className="sidebar-widget-two contact-form-widget">
                                    <div className="inner-box">
                                        <h3>Order Now</h3>
                                        {/* <!-- Contact Form--> */}
                                        <div className="contact-form">
                                            <form onSubmit={(e) => { sendEmail(e) }}>
                                                <div className="row clearfix">
                                                    <div className="col-md-12 form-group">
                                                        <input type="text" name="username" placeholder="Enter name here" required
                                                            onChange={(e) => setFormData({ ...formData, username: e.target.value })} />
                                                        <i className="fal fa-user"></i>
                                                    </div>

                                                    <div className="col-md-12 form-group">
                                                        <input type="email" name="email" placeholder="Enter email here" required
                                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                                                        <i className="fal fa-envelope"></i>
                                                    </div>

                                                    <div className="col-md-12 form-group">
                                                        <input type="number" name="phone" placeholder="Enter Phone Number" required
                                                            onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                                                        <i className="fal fa-phone"></i>
                                                    </div>

                                                    <div className="col-md-12 form-group">
                                                        <input type="textarea" name="address" placeholder="Address" required
                                                            onChange={(e) => setFormData({ ...formData, address: e.target.value })} />
                                                        <i className="fal fa-comment"></i>
                                                    </div>

                                                    <div className="col-md-12 form-group text-center">
                                                        <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="btn-title">Book your Order</span></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Contact Info Widget --> */}

                                {/* <div className="sidebar-widget-two">
                                    {products?.vialProducts?.products && products?.vialProducts?.products?.map((item, i) => (
                                        <div className="gallery-block-one" key={i}>
                                            <div className="inner-box">
                                                <div className="image">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="">
                                                    <h5>{item.title}</h5>
                                                    <h3><span style={{ cursor: "pointer" }} onClick={() => { handleButtonClick(item) }}>{item.name}</span></h3>
                                                    <div className="view-project"><span data-fancybox="example gallery" to="assets/images/gallery/gallery-10.jpg" className="zoom-btn"><span>+</span></span></div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div> */}

                                <div className="sidebar-widget-two contact-info-widget">
                                    <div className="inner-box" style={{ backgroundImage: "url(" + helpbg + ")" }}>
                                        <h3>Need Help?</h3>
                                        <ul>
                                            <li><Link to={'/mailto:sales@prolabsteroids.com'}>sales@prolabsteroids.com</Link></li>
                                        </ul>
                                    </div>
                                </div>

                            </aside>
                        </div>
                        <div className="col-lg-8">
                            <div className="services-details pl-lg-5">
                                <div className="image-box">
                                    <img src={selectedProduct?.image} alt="" />
                                </div>
                                <div className="content">
                                    <div className="sec-title">
                                        <div className="sub-title" style={{fontSize: '24px'}}>
                                            <b>AED </b><span >{selectedProduct?.price}</span>
                                        </div>
                                        <h2>{selectedProduct?.name}</h2>
                                    </div>
                                    {/* <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto . Lorem ipsum dolor sit amet, consectetur adipisicing elit,.</div> */}
                                    <div className='text' dangerouslySetInnerHTML={{ __html: selectedProduct?.details }} />
                                    {/* <blockquote>
                                            <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                                            <h4>Rosalina D. William</h4>
                                        </blockquote> */}
                                    {/* <div className="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto.</div> */}
                                </div>
                                {/* <div className="graph">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <img src={require('../../assets/images/resource/graph-2.png')} alt=""/>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="progress-block">
                                                    <div className="inner-box">
                                                        <CircularProgressbar value={percentage1} text={`${percentage1}%`} />;
                                                    </div>
                                                    <div className="counter-title">Affairs Advisors</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                {/* <div className="loan-calculator">
                                        <div className="row m-0 justify-content-md-between align-items-center">
                                            <div className="sec-title">
                                                <div className="sub-title">Choose Your Type Calculator</div>
                                                <h2>Loan Calculator</h2>
                                            </div>
                                            <div className="calculate-btn">
                                                <button>EMI</button>
                                                <button className="active">Savings</button>
                                            </div>
                                        </div>                                
                                        <div className="progress-bar-outer">
                                            <div className="progress-box">
                                                <div className="bar">
                                                    <ProgressBar animated={true} className="count-bar" now={64} />
                                                </div>
                                                <div className="row m-0 justify-content-between">
                                                    <div className="progress-value">$5,000</div>
                                                    <div className="total-value">$20,000</div>
                                                </div>
                                            </div>
                                            <div className="progress-box">
                                                <div className="bar">
                                                    <ProgressBar animated={true} className="count-bar" now={34} />
                                                </div>
                                                <div className="row m-0 justify-content-between">
                                                    <div className="progress-value">12</div>
                                                    <div className="total-value">84</div>
                                                </div>
                                            </div>
                                            <div className="progress-box">
                                                <div className="bar">
                                                    <ProgressBar animated={true} className="count-bar" now={84} />
                                                </div>
                                                <div className="row m-0 justify-content-between">
                                                    <div className="progress-value">0.00%</div>
                                                    <div className="total-value">30.00%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="calculation-value">
                                            <div className="row">
                                                <div className="single-value col-md-3 col-sm-6">
                                                    <div className="inner-box">
                                                        <h4>$7200</h4>
                                                        <h5>Amount</h5> 
                                                    </div>                       
                                                </div>
                                                <div className="single-value col-md-3 col-sm-6">
                                                    <div className="inner-box">
                                                        <h4>36 mo</h4>
                                                        <h5>Term</h5> 
                                                    </div>                       
                                                </div>
                                                <div className="single-value col-md-3 col-sm-6">
                                                    <div className="inner-box">
                                                        <h4>22.55%</h4>
                                                        <h5>Interest</h5> 
                                                    </div>                       
                                                </div>
                                                <div className="single-value col-md-3 col-sm-6">
                                                    <div className="inner-box">
                                                        <h4>$2,785</h4>
                                                        <h5>Monthly</h5>
                                                    </div>                       
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )
}
export default ServicesDetails;