import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import Brand1 from '../element/brand1'
import PortfolioFilter1 from '../element/portfolio-filter1';

const aboutbg = require('./../../assets/images/background/imgbg.jpg');

class Portfolio1 extends Component {

    componentDidMount() {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
                <Header />
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Our Products</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Products</li>
                        </ul>
                    </div>
                </section>
                <PortfolioFilter1 />
                <Footer />
            </>
        )
    }
}
export default Portfolio1;