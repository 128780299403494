import React, { useState } from "react";
import { Link, withRouter } from 'react-router-dom';

import { products } from '../../data/product';

const PortfolioFilter1 = (props) => {
    const handleButtonClick = (item) => {
        props.history.push(`/product/${item.slug}`);
    };

    return (
        <>
            <section className="gallery-section">
            <div className="sortable-masonry">
                    <div className="auto-container">
                        <div className="row m-0 justify-content-md-between align-items-center">
                            <div className="sec-title">
                                <div className="sub-title">{products?.vialProducts?.title}</div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-container">
                        <div className="items-container row">

                            {products?.vialProducts?.products && products?.vialProducts?.products?.map((item, i) => (
                                <div className="col-lg-4 col-md-6 gallery-block-one" key={i} style={{cursor: "pointer"}} onClick={() => { handleButtonClick(item) }}>
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="caption-title">
                                            <h5 style={{padding: 0, fontSize: '18px'}}><b>AED </b><span >{item?.price}</span></h5>
                                            <h3 id="product-name"><span >{item.name}</span></h3>
                                            {/* <p><span >200 AED</span></p> */}
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-10.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                <div className="sortable-masonry" style={{marginTop: '100px'}}>
                    <div className="auto-container">
                        <div className="row m-0 justify-content-md-between align-items-center">
                            <div className="sec-title">
                                <div className="sub-title">{products?.tabletProducts?.title}</div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-container">
                        <div className="items-container row">

                            {products?.tabletProducts?.products && products?.tabletProducts?.products?.map((item, i) => (
                                <div className="col-lg-4 col-md-6 gallery-block-one" key={i} style={{cursor: "pointer"}} onClick={() => { handleButtonClick(item) }}>
                                    <div className="inner-box">
                                        <div className="image">
                                            <img src={item.image} alt="" loading="lazy" />
                                        </div>
                                        <div className="caption-title">
                                            <h5 style={{padding: 0, fontSize: '18px'}}><b>AED </b><span >{item?.price}</span></h5>
                                            <h3 id="product-name"><span >{item.name}</span>
                                            {/* <h4><b>AED </b><span >200</span></h4> */}
                                            </h3>
                                            <div className="view-project"><Link data-fancybox="example gallery" to="assets/images/gallery/gallery-10.jpg" className="zoom-btn"><span>+</span></Link></div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withRouter(PortfolioFilter1);
