import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Footer from '../layout/footer';
import Brand1 from '../element/brand1';
// import Header2 from '../layout/header2';
import Header from '../layout/header';


const aboutbg = require('./../../assets/images/background/imgbg.jpg');



class About extends Component {


    render() {
        return (
            <>
                <Header />
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="#">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>


                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>About Us</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">About Us</li>
                        </ul>
                    </div>

                </section>


                {/* About Section Two */}
                <section class="about-section-two">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="image-wrapper">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/aboutus/about.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="content-box">
                                    <div class="sec-title">
                                        <div class="sub-title">WHAT ABOUT US</div>
                                        <h2 style={{ fontSize: '48px' }}>YOUR #1 ANABOLIC STEROIDS, HGH, PEPTIDES & SARMS</h2>
                                        <div class="text">
                                            <p>
                                                The pro lab was established in Germany in 2017 as a company specializing in pharmaceutical production strictly as per international standards. Healthiness and wellness are critical factors for each of us, and finding sustainable solutions to our world’s most pressing health care challenges can’t wait.
                                            </p>
                                            <p>
                                                That’s why we at The Pro Lab are always devoted to enhance your health and well-being at each stage of life. Every single day we strive to offer the most effective, safe and affordable pharmaceuticals.
                                            </p>
                                            <p>
                                                Top-notch quality standard is the key value here at The pro lab. A group of quality assurance experts keep an eye on each and every process performance through product conformity followed up by advanced GMP practices and activities to achieve constant progress in overall quality and innovations.
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div class="author-info">
                                        <div class="">
                                            <h2>Our Mission</h2>
                                            {/* <div class="designation">Founder</div> */}
                                            <div class="text">
                                                We promise genuine pharmaceuticals that will help men to live more active and satisfying lives.
                                            </div>
                                        </div>
                                        {/* <div class="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" /></div> */}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div class="author-info">
                                        <div class="">
                                            <h2>Our Vision</h2>
                                            {/* <div class="designation">Founder</div> */}
                                            <div class="text">
                                            We will substantially contribute to humanity by enhancing men health, self-confidence and overall wellness in the 21st century.
                                            </div>
                                        </div>
                                        {/* <div class="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" /></div> */}
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div class="author-info">
                                        <div class="">
                                            <h2>OUR KEY VALUES</h2>
                                            {/* <div class="designation">Founder</div> */}
                                            <div class="text">
                                                <ul className='row'>
                                                <li className='m-1'><i class="fal fa-circle"></i> Top-Notch Quality</li>
                                                <li className='m-1'><i class="fal fa-circle"></i> Reliability</li>
                                                <li className='m-1'><i class="fal fa-circle"></i> Consistency</li>
                                                <li className='m-1'><i class="fal fa-circle"></i> Safety</li>
                                                <li className='m-1'><i class="fal fa-circle"></i> Efficiency</li>
                                                <li className='m-1'><i class="fal fa-circle"></i> Affordability</li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div class="signature"><img src={require('../../assets/images/resource/signature.png')} alt="" /></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* History Section */}

                {/* Team Section */}

                {/* Services Section Four */}
                <section class="services-section-four services-section-about">
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Protection Of Our Products</div>
                            <h2>Protection</h2>
                        </div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/aboutus/aboutus1.png')} alt="" /></div>
                                    {/* <h2>Capital Market</h2> */}
                                    <div class="text">The logo on the bottle is made in ceramic dye.</div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/aboutus/aboutus2.png')} alt="" /></div>
                                    {/* <h2>Finance Division</h2> */}
                                    <div class="text">Embedded logo on the cap. Logo’s edges are clear and deep. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/aboutus/aboutus3.png')} alt="" /></div>
                                    {/* <h2>Insurance & Planning</h2> */}
                                    <div class="text">Hologram on the label. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/aboutus/aboutus4.png')} alt="" /></div>
                                    {/* <h2>Family Case</h2> */}
                                    <div class="text">Word PHARMA on the box is applied in foil. </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-5.png')} alt="" /></div>
                                    <h2>Financial Strategy</h2>
                                    <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="icon-box">
                                    <div class="icon"><img src={require('../../assets/images/icons/icon-23.png')} alt="" /></div>
                                    <h2>Financial Planning</h2>
                                    <div class="text">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                {/* Sponsors Section */}


                {/*<Brand1 />*/}






                <Footer />
            </>
        )
    }
}
export default About;