import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import GoogleMaps from "simple-react-google-maps";
const aboutbg = require('./../../assets/images/background/imgbg.jpg');




class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            email: '',
            message: '',
        };
    }
    
    sendEmail = async (e) => {
        e.preventDefault();
        console.log("workssss", e);

        window.Email.send({
            Host : "smtp.elasticemail.com",
            Port: 2525,
            Username : "marketing@prolabsteroids.com",
            Password : "4473F16DE8792142479285FDAE056D7D880A",
            To : 'sales@prolabsteroids.com',
            From : "marketing@prolabsteroids.com",
            Subject : "Enquiry From Pro Lab Website",
            Body : `<p>Name: ${this.state.username}</p><br><p>Email: ${this.state.email}</p><br><p>Message: ${this.state.message}</p>`
        }).then(
          message => alert("Successfully submitted")
        );
    };

    render() {
        return (
            <>
                <Header />


                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>

                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Contact Us</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Contact</li>
                        </ul>
                    </div>

                </section>

                {/* <!-- Map Section --> */}
                {/* <!-- Map Section --> */}
                {/*<section class="map-section">*/}
                {/*    <div class="map-column">*/}
                {/*        <GoogleMaps*/}
                {/*            apiKey={"AIzaSyBQ5y0EF8dE6qwc03FcbXHJfXr4vEa7z54"}*/}
                {/*            style={{ height: "800px", width: "100%" }}*/}
                {/*            zoom={12}*/}
                {/*            center={{ lat: 37.4224764, lng: -122.0842499 }}*/}
                {/*            markers={{ lat: 37.4224764, lng: -122.0842499 }} //optional*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</section>*/}

                {/* <!-- Contact Section Two --> */}
                <section class="contact-section-two">
                    <div class="auto-container">
                        <div class="contact-form-area">
                            <div class="sec-title text-center">
                                <div class="sub-title">Write Here</div>
                                <h2>Get In Touch</h2>
                            </div>
                            {/* <!-- Contact Form--> */}
                            <div class="contact-form">
                                <form onSubmit={(e) => { this.sendEmail(e) }} id="contact-form">
                                    <div class="row clearfix">
                                        <div class="col-md-6 form-group">
                                            <label for="name">Enter your name</label>
                                            <input type="text" required name="username" id="name" placeholder="Enter name here......"   onChange={(e) => this.setState({ username: e.target.value })} />
                                            <i class="fas fa-user"></i>
                                        </div>

                                        <div class="col-md-6 form-group">
                                            <label for="email">Enter your email</label>
                                            <input type="email" required name="email" id="email" placeholder="Enter email here......"  onChange={(e) => this.setState({ email: e.target.value })} />
                                            <i class="fas fa-envelope"></i>
                                        </div>

                                        <div class="col-md-12 form-group">
                                            <label for="message">Enter your message</label>
                                            <textarea name="message" required id="message" placeholder="Enter message here......" onChange={(e) => this.setState({ message: e.target.value })}></textarea>
                                            <i class="fas fa-edit"></i>
                                        </div>

                                        <div class="col-md-12 form-group">
                                            <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Get In Touch</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>






                <Footer />
            </>
        )
    }
}
export default Contact;