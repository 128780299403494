import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header2 from '../layout/header2';
import HomeSlider2 from '../element/home-slider2';
import Footer from '../layout/footer';
import Testimonial1 from '../element/testimonial1'
import VideoPopup2 from './../element/video-popup2'
import '../../assets/css/color-2.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { withRouter } from 'react-router-dom';

import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Header from '../layout/header';

import { products } from '../../data/product';

const counterbg1 = require('./../../assets/images/background/image-5.jpg');
const touchbg1 = require('./../../assets/images/background/image-8.jpg');

class Index2 extends Component {

    handleButtonClick(item) {
        this.props.history.push(`/product/${item.slug}`);
    };

    render() {

        return (
            <>
                <Header />


                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form>
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn" />
                                    </fieldset>
                                </div>
                            </form>
                            <br />
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>

                    </div>
                </div>

                <HomeSlider2 />


                {/* <!-- Feature Section --> */}

                {/* <section class="feature-section">
                    <div class="auto-container">
                        <div class="wrapper-box">
                            <div class="row">
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-team"></span></div>
                                            <h5>Management</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-money"></span></div>
                                            <h5>TAX Consume</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-assets"></span></div>
                                            <h5>Finance & Strategy</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-world"></span></div>
                                            <h5>Best Advice</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-money-1"></span></div>
                                            <h5>Insurance</h5>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6">
                                    <div class="feature-block-one">
                                        <div class="inner-box">
                                            <div class="icon"><span class="flaticon-notebook"></span></div>
                                            <h5>Criminal Case</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* <!-- About Section Two --> */}
                <section class="about-section-two">
                    <div class="auto-container">
                        <div class="row align-items-center">
                            <div class="col-lg-6">
                                <div class="content-box wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="sec-title">
                                        <div class="sub-title">About</div>
                                        <h3 style={{ fontSize: "30px" }}><b>BEST TRUSTED EXPERTS SUPPLEMENTS TECHNLOGY</b></h3>
                                        <div class="text">
                                            The Pro Lab was established as a company specializing in pharmaceutical production strictly as per international standards. Healthiness and wellness are critical factors for each of us.
                                        </div>
                                        <ul>
                                            <li className='my-1'><i class="fal fa-bong"></i> Improved Physical Appearance</li>
                                            <li className='my-1'><i class="fal fa-bong"></i> Increased Muscle Mass</li>
                                            <li className='my-1'><i class="fal fa-bong"></i> Increased Strength & Endurance</li>
                                            <li className='my-1'><i class="fal fa-bong"></i> More Intense Training Ability</li>
                                            <li className='my-1'><i class="fal fa-bong"></i> Boosted Overall Sporting Performance</li>
                                            <li className='my-1'><i class="fal fa-bong"></i> Greatly Increased Sexual Drive</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="image-wrapper wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                    <div class="image-one">
                                        <img src={require('../../assets/images/aboutus/about1.jpg')} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                {/* <!-- Funfacts Section --> */}



                {/* <!-- Statistic Section --> */}


                {/* <!-- Services Section Two --> */}


                {/* <section class="gallery-section" style={{ paddingTop: 0 }}>
                    <div class="auto-container">
                        <div class="sec-title text-center">
                            <div class="sub-title">Discover</div>
                            <h2>Products</h2>
                        </div>
                        <div class="row">
                            {products?.vialProducts?.products && products?.vialProducts?.products?.map((item, i) => {
                                return <div class="col-lg-4 col-md-6 style-two gallery-block-one" key={i} style={{ cursor: 'pointer' }} onClick={() => { this.handleButtonClick(item) }}>
                                    <div class="inner-box">
                                        <div class="image">
                                            <img src={item?.image} alt="" />
                                        </div>
                                        <div class="caption-title">
                                            <h5 style={{ padding: 0, fontSize: '18px' }}><b>AED </b><span >{item?.price}</span></h5>
                                            <h3><span>{item?.name}</span></h3>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className='row justify-content-center'><button className='btn btn-primary ' onClick={() => { this.props.history.push('/products') }}>Show More</button></div>
                    </div>
                </section> */}

                <Footer />
            </>
        )
    }
}
export default withRouter(Index2);