import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <>
                <footer class="main-footer">
                    <div class="auto-container">

                        <div class="widgets-section">
                            <div class="row clearfix">


                                <div class="column col-lg-4">
                                    <div class="footer-widget logo-widget">
                                        <div class="widget-content">
                                            <div class="footer-logo">
                                                {/* <Link to="index.html"><img class="" src={require('../../assets/images/footer-logo.png')} alt="" /></Link> */}
                                                <h2>Pro Lab</h2>
                                            </div>
                                            {/* <div class="text">Feel free to contact us via e-mail and social media regarding any of our products, order inquiries, payment or shipping details, wholesale price list or any other question.</div> */}
                                            <div class="text">YOUR #1 ANABOLIC STEROIDS, HGH, PEPTIDES & SARMS SHOP.</div>
                                            <ul class="social-links clearfix">
                                                <li><Link to={'https://www.facebook.com/profile.php?id=61556948169731&mibextid=LQQJ4d'}><span class="fab fa-facebook-f"></span></Link></li>
                                                <li><Link to={'https://www.instagram.com/prolabsterioids?igsh=ZHc3MGYwOTlza3U4&utm_source=qr'}><span class="fab fa-instagram"></span></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div class="column col-lg-8">
                                    <div class="footer-widget links-widget">
                                        <div class="widget-content">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <h3>Links</h3>
                                                    <ul>
                                                        <li><Link to={'/'}>Home</Link></li>
                                                        <li><Link to={'/about'}>About</Link></li>
                                                        <li><Link to={'/products'}>Products</Link></li>
                                                        <li><Link to={'/contact'}>Contact</Link></li>
                                                        {/* <li><Link to={'/#'}>Services</Link></li>
                                                        <li><Link to={'/#'}>Portfolio</Link></li>
                                                        <li><Link to={'/#'}>Pricing</Link></li> */}
                                                        {/* <li><Link to={'/#'}>Contact</Link></li> */}
                                                    </ul>
                                                </div>
                                                <div class="col-md-6">
                                                    <h3>Contact Us</h3>

                                                    <ul>

                                                        <li>Dubai, UAE</li>
                                                        <li>sales@prolabsteroids.com</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <div class="column col-lg-4">
                                    <div class="footer-widget instagram-widget">
                                        <h3>Photo Showcase</h3>
                                        <div class="inner-box">
                                            <div class="wrapper-box">
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-1.jpg')} alt="" />
                                                    
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-1.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-2.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-2.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-3.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-3.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-4.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-4.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-5.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-5.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-6.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-6.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-7.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-7.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-8.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-8.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                                <div class="image">
                                                    <img class="lazy-image owl-lazy" src={require('../../assets/images/gallery/gallery-9.jpg')} alt="" />
                                                    <div class="overlay-link"><Link to={'/assets/images/gallery/gallery-9.jpg'} class="lightbox-image" data-fancybox="gallery"><span class="fa fa-plus"></span></Link></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>

                        </div>
                    </div>

                    {/* <div class="auto-container">
                        <div class="footer-bottom">
                            <div class="copyright">Copyright By<Link to={'/#'}>Webtend</Link> - 2020</div>
                        </div>
                    </div> */}
                </footer>


            </>
        );
    }
}

export default Footer;