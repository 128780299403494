import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'

const aboutbg = require('./../../assets/images/background/imgbg.jpg');



class Checkout extends Component {
    constructor(props) {
        super(props);

        let cart = JSON.parse(localStorage?.getItem('cart')) || [];
        if (cart.lenght <= 0) {
            this.props.history.push('/');
        }
        this.state = {
            cartItems: cart,
            country: '',
            firstName: '',
            lastName: '',
            address: '',
            town: '',
            email: '',
            number: ''
        };
    }

    componentDidMount() {
        if (this.state.cartItems.length <= 0) {
            this.props.history.push('/cart');
        }
    }

    calculateTotalAmount = () => {
        const { cartItems } = this.state;
        let totalAmount = 0;

        // Iterate through the cart items and calculate the total amount
        cartItems.forEach(item => {
            const itemTotal = parseFloat(item.price) * item.quantity;
            totalAmount += itemTotal;
        });

        return totalAmount;
    }

    sendEmail = async (e) => {
        e.preventDefault();
        console.log("workssss", e);

        window.Email.send({
            Host: "smtp.elasticemail.com",
            Port: 2525,
            Username: "marketing@prolabsteroids.com",
            Password: "4473F16DE8792142479285FDAE056D7D880A",
            To: 'sales@prolabsteroids.com',
            From: "marketing@prolabsteroids.com",
            Subject: "Order From Pro Lab Website",
            Body: `<html>
            <head>
              <style>
                /* Add CSS styles for your email template here */
              </style>
            </head>
            <body><div>
            <p>Name: ${this.state.firstName + ' ' + this.state.lastName}</p>
            <p>Address: ${this.state.address}</p>
            <p>Town: ${this.state.town}</p>
            <p>Country: ${this.state.country}</p>
            <p>Email: ${this.state.email}</p>
            <p>Phone: ${this.state.number}</p>
            <br>
            <table>
            <thead>
            <tr>
                <th>Product</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Image</th>
            </tr>
            </thead>
            <tbody>
            ${this.state.cartItems && this.state.cartItems.map(product => `
                <tr>
                <td>${product.name}</td>
                <td>${product.price}</td>
                <td>${product.quantity}</td>
                <td><img src="${product.image}" alt="${product.name}" width="50" height="50"></td>
                </tr>
            `).join('')}
            </tbody>
            </table>
                <div>
                </body>
            </html>`
        }).then(
            message => alert("Your order submitted successfully")
        );
    };


    render() {
        return (
            <>
                <form onSubmit={(e) => { this.sendEmail(e) }}>
                    <Header />
                    <div id="search-popup" class="search-popup">
                        <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                        <div class="popup-inner">
                            <div class="overlay-layer"></div>

                        </div>
                    </div>

                    {/* <!-- Page Banner Section --> */}
                    <section class="page-banner">
                        <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                        <div class="bottom-rotten-curve alternate"></div>

                        <div class="auto-container">
                            <h1>Checkout</h1>
                            <ul class="bread-crumb clearfix">
                                <li><Link to={'/#'}>Home</Link></li>
                                <li class="active">Checkout</li>
                            </ul>
                        </div>

                    </section>
                    <section class="checkout-area">
                        <div class="bottom">
                            <div class="auto-container">
                                <div class="row">
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div class="table">
                                            <div class="shop-title-box">
                                                <h3>Order Summary</h3>
                                            </div>
                                            <table class="cart-table">
                                                <thead class="cart-header">
                                                    <tr>
                                                        <th class="product-column">Products</th>
                                                        <th>Name</th>
                                                        <th>Quantity</th>
                                                        <th class="price">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.cartItems && this.state.cartItems?.map((item, i) => {
                                                        return <tr key={i}>
                                                            <td class="product-column">
                                                                <div class="column-box">
                                                                    <div class="prod-thumb">
                                                                        <Link to={'/#'}><img src={item?.image} alt="" /></Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td class="price" align='center'>{item?.name}</td>
                                                            <td class="price">
                                                                <input class="quantity-spinner" style={{ marginLeft: '50px' }} type="text" value={item?.quantity} name="quantity" />
                                                            </td>
                                                            <td class="price">{item?.price}</td>
                                                        </tr>
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                        <div class="cart-total">
                                            <div class="shop-title-box">
                                                <h3>Cart Totals</h3>
                                            </div>
                                            <ul class="cart-total-table">
                                                <li class="clearfix">
                                                    <span class="col col-title">Cart Subtotal</span>
                                                    <span class="col">AED {this.calculateTotalAmount()}</span>
                                                </li>
                                                <li class="clearfix">
                                                    <span class="col col-title">Shipping Method</span>
                                                    <span class="col">Free Delivery</span>
                                                </li>
                                                <li class="clearfix">
                                                    <span class="col col-title">Payment</span>
                                                    <span class="col">Cash on Delivery (COD)</span>
                                                </li>
                                                <li class="clearfix">
                                                    <span class="col col-title">Order Total</span>
                                                    <span class="col">AED {this.calculateTotalAmount()}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="auto-container">
                            <div class="row" style={{ placeContent: 'center' }}>
                                <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                                    <div class="form billing-info">
                                        <div class="shop-title-box">
                                            <h3>Delivery Address</h3>
                                        </div>
                                        <form method="post" action="http://azim.commonsupport.com/Finandox/checkout.html">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="field-label">Country*</div>
                                                    <div class="field-input">
                                                        <input type="text" required name="country" placeholder="United Arab Emirates"
                                                            onChange={(e) => { this.setState({ ...this.state, country: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="field-label">First Name*</div>
                                                    <div class="field-input">
                                                        <input type="text" required name="fname" placeholder=""
                                                            onChange={(e) => { this.setState({ ...this.state, firstName: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="field-label">Last Name *</div>
                                                    <div class="field-input">
                                                        <input type="text" required name="lname" placeholder=""
                                                            onChange={(e) => { this.setState({ ...this.state, lastName: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="field-label">Address *</div>
                                                    <div class="field-input">
                                                        <input type="text" required name="address" placeholder=""
                                                            onChange={(e) => { this.setState({ ...this.state, address: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="field-label">Town / City *</div>
                                                    <div class="field-input">
                                                        <input type="text" required name="town-city" placeholder=""
                                                            onChange={(e) => { this.setState({ ...this.state, town: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="field-label">Contact Info *</div>
                                                    <div class="field-input">
                                                        <input type="text" required name="email" placeholder="Email Address"
                                                            onChange={(e) => { this.setState({ ...this.state, email: e.target.value }) }} />
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="field-input">
                                                        <input type="text" name="phone" placeholder="Phone Number"
                                                            onChange={(e) => { this.setState({ ...this.state, number: e.target.value }) }}
                                                            required={true} />
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-12">
                                                <div class="create-acc">
                                                    <div class="checkbox">
                                                        <label>
                                                            <input type="checkbox" name="ship-same-address" />
                                                            <span>Create an Account</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                                                <div class="col-md-12" style={{marginTop: '24px', marginBottom: '100px'}}>
                                                    <div class="payment-options">
                                                        <div class="placeorder-button text-left" style={{ float: 'right' }}>
                                                            <button class="theme-btn btn-style-one" type="submit"><span class="btn-title">Place Order</span></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    <Footer />
                </form>
            </>
        )
    }
}
export default Checkout;